import React, { Component } from "react";
import { storage } from "../../firebase";
// @ts-ignore
import file from "./c360v.htm";

class Customer360Viewer extends Component {
  componentDidMount() {
    fetch(file)
      .then((res) => res.text())
      // @ts-ignore
      .then((text) => {
        // HTMLを付与
        // @ts-ignore
        document.querySelector("#inner").innerHTML = text;
        function getGcsFile(filePath: string) {
          const storageRef = storage.ref();
          storageRef
            .child(filePath)
            .getDownloadURL()
            .then((url: any) => {
              window.open(url, "_blank", "noopener noreferrer");
            })
            .catch((err) => console.log(err));
        }
        const userManual = document.querySelector("#user-manual");
        const operationDept  = document.querySelector("#operation-dept");
        const planningDept  = document.querySelector("#planning-dept");
        // @ts-ignore
        userManual.addEventListener("click", function () {
          getGcsFile(
            "customer-360-viewer/manual/Consumer360°Viewer_UserManual.pdf"
          );
        });
        // @ts-ignore
        operationDept.addEventListener("click", function () {
          getGcsFile(
            "customer-360-viewer/manual/Consumer360°Viewer_Usage_OperationDept.pdf"
          );
        });
        // @ts-ignore
        planningDept.addEventListener("click", function () {
          getGcsFile(
            "customer-360-viewer/manual/Consumer360°Viewer_Usage_PlanningDept.pdf"
          );
        });
      });
  }
  render() {
    return <div id="inner"></div>;
  }
}
export default Customer360Viewer;
