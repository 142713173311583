/**
 * 共通処理です
 * @class util.ts
 * @constructor
 */
//  import { useDispatch } from "react-redux";
//  import { setIsDeviceType } from "../features/userSlice";

//=============================================================================
//  制御、その他
//=============================================================================
/**
 * 別タブ遷移
 * @param url : url文字列
 */
export const openWebSite = (url: string) => {
  window.open(url, "_blank", "noreferrer noopener");
};

/**
 * デバイス種別取得
 * @returns デバイス種別
 */
export const getDeviceType = (): string => {
  const ua = navigator.userAgent;
  const loerUa = navigator.userAgent.toLowerCase();
  if (ua.indexOf("iPhone") > -1) {
    return "iphone";
  } else if (ua.indexOf("Android") > -1 && ua.indexOf("Mobile") > -1) {
    return "android";
  } else if (ua.indexOf("iPad") > -1) {
    return "ipad";
  } else if (/ipad|macintosh/.test(loerUa) && "ontouchend" in document) {
    return "ipad";
  } else if (ua.indexOf("Android") > -1) {
    return "android";
  } else {
    return "pc";
  }
};

//=============================================================================
//  時間・日付
//=============================================================================
/**
 * UnixTimeの数値を返す
 * @returns {number} UNIXTIME  例:1639381897
 */
export const getUnixTime = (): number => {
  // Dateオブジェクトを作成し、UNIXタイムスタンプを取得する (ミリ秒単位)
  const unixTimestamp = new Date().getTime();
  // UNIXタイムスタンプを取得する (秒単位 - PHPのtime()と同じ)
  return Math.floor(unixTimestamp / 1000);
};

export const getNowYMD = (): string => {
  var dt = new Date();
  var y = dt.getFullYear();
  var m = ("00" + (dt.getMonth() + 1)).slice(-2);
  var d = ("00" + dt.getDate()).slice(-2);
  var result = y + m + d;
  return result;
};

//=============================================================================
//  URL操作
//=============================================================================
/**
 * URLと指定したパラメータ名を受けて、存在する場合はその値で返す 存在しない場合は「""」で返す
 * @param {String} : name パラメータ名
 * @param {String} : url 「window.location.href」で取得したURL
 * @returns {String} パラメータの値
 */
export const getParam = (name: string, url: string): string => {
  if (!url) url = window.location.href;
  name = name.replace(/[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return "";
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

/**
 * URLよりパラメータを返す
 * @param {String} : url 「window.location.search」の値
 * @returns {String} パラメータの値
 */
export const getParametersString = (urlSearch: string) => {
  // 値がない場合は空で返される
  return urlSearch.substring(1);
};
