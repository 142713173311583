import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { TENPOSTATUS } from "./interface";

const initialTenpoStatus: TENPOSTATUS = {
  tenpoCode: "",
  tenpoName: "",
  isKunren: true,
  isEigyo: true,
  isTenpoNoProblem: true,
  isTenpoHinan: false,
  isTenpoSonkai: false,
  isTenpoShift: false,
  isTenpoTeiden: false,
  isTenpoDansui: false,
  isTenpoTraffic: false,
  isTenpoOther: false,
  tenpoComment: "",
  tenpoFromDate: "",
  tenpoFromHour: "",
  tenpoToDate: "",
  tenpoToHour: "",
  isKeganashi: true,
  isGuest: false,
  isOwner: false,
  isAssistant: false,
  isEmployee: false,
  isFamily: false,
  isOtherPersons: false,
  isGoodsDamaged: false,
  isZyoon: false,
  isBeihan: false,
  isChilled: false,
  isFrozen: false,
};

const makeBooleanValue = (value: number): boolean => {
  return value === 1 ? true : false;
};

export const tenpoStatusSlice = createSlice({
  name: "tenpoStatus",
  initialState: {
    tenpoStatus: initialTenpoStatus,
  },
  reducers: {
    logout: (state) => {
      state.tenpoStatus = initialTenpoStatus;
    },
    updateTenpoCode: (state, action) => {
      state.tenpoStatus.tenpoCode = action.payload.tenpoCode;
    },
    updateIsKunren: (state, action) => {
      state.tenpoStatus.isKunren = action.payload.isKunren;
    },
    updateIsEigyo: (state, action) => {
      state.tenpoStatus.isEigyo = action.payload.isEigyo;
    },
    updateTenpoEigyoStatus: (state, action) => {
      if (action.payload.isTenpoNoProblem !== undefined) {
        state.tenpoStatus.isTenpoNoProblem = action.payload.isTenpoNoProblem;
      } else if (action.payload.isTenpoHinan !== undefined) {
        state.tenpoStatus.isTenpoHinan = action.payload.isTenpoHinan;
      } else if (action.payload.isTenpoSonkai !== undefined) {
        state.tenpoStatus.isTenpoSonkai = action.payload.isTenpoSonkai;
      } else if (action.payload.isTenpoShift !== undefined) {
        state.tenpoStatus.isTenpoShift = action.payload.isTenpoShift;
      } else if (action.payload.isTenpoTeiden !== undefined) {
        state.tenpoStatus.isTenpoTeiden = action.payload.isTenpoTeiden;
      } else if (action.payload.isTenpoDansui !== undefined) {
        state.tenpoStatus.isTenpoDansui = action.payload.isTenpoDansui;
      } else if (action.payload.isTenpoTraffic !== undefined) {
        state.tenpoStatus.isTenpoTraffic = action.payload.isTenpoTraffic;
      } else if (action.payload.isTenpoOther !== undefined) {
        state.tenpoStatus.isTenpoOther = action.payload.isTenpoOther;
      } else if (action.payload.tenpoComment !== undefined) {
        state.tenpoStatus.tenpoComment = action.payload.tenpoComment;
      } else if (action.payload.tenpoFromDate !== undefined) {
        state.tenpoStatus.tenpoFromDate = action.payload.tenpoFromDate;
      } else if (action.payload.tenpoFromHour !== undefined) {
        state.tenpoStatus.tenpoFromHour = action.payload.tenpoFromHour;
      } else if (action.payload.tenpoToDate !== undefined) {
        state.tenpoStatus.tenpoToDate = action.payload.tenpoToDate;
      } else if (action.payload.tenpoToHour !== undefined) {
        state.tenpoStatus.tenpoToHour = action.payload.tenpoToHour;
      }
    },
    updateTenpoAnpiStatus: (state, action) => {
      if (action.payload.isKeganashi !== undefined) {
        state.tenpoStatus.isKeganashi = action.payload.isKeganashi;
      } else if (action.payload.isGuest !== undefined) {
        state.tenpoStatus.isGuest = action.payload.isGuest;
      } else if (action.payload.isOwner !== undefined) {
        state.tenpoStatus.isOwner = action.payload.isOwner;
      } else if (action.payload.isAssistant !== undefined) {
        state.tenpoStatus.isAssistant = action.payload.isAssistant;
      } else if (action.payload.isEmployee !== undefined) {
        state.tenpoStatus.isEmployee = action.payload.isEmployee;
      } else if (action.payload.isFamily !== undefined) {
        state.tenpoStatus.isFamily = action.payload.isFamily;
      } else if (action.payload.isOtherPersons !== undefined) {
        state.tenpoStatus.isOtherPersons = action.payload.isOtherPersons;
      }
    },
    updateTenpoShopGoodsStatus: (state, action) => {
      state.tenpoStatus.isGoodsDamaged = action.payload.isGoodsDamaged;
    },
    updateTenpoDelivery: (state, action) => {
      if (action.payload.isZyoon !== undefined) {
        state.tenpoStatus.isZyoon = action.payload.isZyoon;
      } else if (action.payload.isBeihan !== undefined) {
        state.tenpoStatus.isBeihan = action.payload.isBeihan;
      } else if (action.payload.isChilled !== undefined) {
        state.tenpoStatus.isChilled = action.payload.isChilled;
      } else if (action.payload.isFrozen !== undefined) {
        state.tenpoStatus.isFrozen = action.payload.isFrozen;
      }
    },
    updateTenpoStatus: (state, action) => {
      if (action.payload.name !== null) {
        state.tenpoStatus.tenpoName = action.payload.name;
      }
      if (action.payload.eigyo_flg !== undefined) {
        state.tenpoStatus.isEigyo = makeBooleanValue(
          Number(action.payload.eigyo_flg || 0)
        );
      }
      if (action.payload.hason_flg !== undefined) {
        state.tenpoStatus.isTenpoSonkai = makeBooleanValue(
          Number(action.payload.hason_flg || 0)
        );
      }
      if (action.payload.shift_flg !== undefined) {
        state.tenpoStatus.isTenpoShift = makeBooleanValue(
          Number(action.payload.shift_flg || 0)
        );
      }
      if (action.payload.dansui_flg !== undefined) {
        state.tenpoStatus.isTenpoDansui = makeBooleanValue(
          Number(action.payload.dansui_flg || 0)
        );
      }
      if (action.payload.teiden_flg !== undefined) {
        state.tenpoStatus.isTenpoTeiden = makeBooleanValue(
          Number(action.payload.teiden_flg || 0)
        );
      }
      if (action.payload.koutuu_flg !== undefined) {
        state.tenpoStatus.isTenpoTraffic = makeBooleanValue(
          Number(action.payload.koutuu_flg || 0)
        );
      }
      if (action.payload.sonota_flg !== undefined) {
        state.tenpoStatus.isTenpoOther = makeBooleanValue(
          Number(action.payload.sonota_flg || 0)
        );
      }
      if (action.payload.anpi_keganashi !== undefined) {
        state.tenpoStatus.isKeganashi = makeBooleanValue(
          Number(action.payload.anpi_keganashi || 0)
        );
      }
      if (action.payload.anpi_geust !== undefined) {
        state.tenpoStatus.isGuest = makeBooleanValue(
          Number(action.payload.anpi_geust || 0)
        );
      }
      if (action.payload.anpi_owner !== undefined) {
        state.tenpoStatus.isOwner = makeBooleanValue(
          Number(action.payload.anpi_owner || 0)
        );
      }
      if (action.payload.anpi_assistant !== undefined) {
        state.tenpoStatus.isAssistant = makeBooleanValue(
          Number(action.payload.anpi_assistant || 0)
        );
      }
      if (action.payload.anpi_employee !== undefined) {
        state.tenpoStatus.isEmployee = makeBooleanValue(
          Number(action.payload.anpi_employee || 0)
        );
      }
      if (action.payload.anpi_owner_family !== undefined) {
        state.tenpoStatus.isFamily = makeBooleanValue(
          Number(action.payload.anpi_owner_family || 0)
        );
      }
      if (action.payload.anpi_other !== undefined) {
        state.tenpoStatus.isOtherPersons = makeBooleanValue(
          Number(action.payload.anpi_other || 0)
        );
      }
      if (action.payload.syouhin_damage !== undefined) {
        state.tenpoStatus.isGoodsDamaged = makeBooleanValue(
          Number(action.payload.syouhin_damage || 0)
        );
      }
      if (action.payload.delivery_jouon !== undefined) {
        state.tenpoStatus.isZyoon = makeBooleanValue(
          Number(action.payload.delivery_jouon || 0)
        );
      }
      if (action.payload.delivery_beihan_bread !== undefined) {
        state.tenpoStatus.isBeihan = makeBooleanValue(
          Number(action.payload.delivery_beihan_bread || 0)
        );
      }
      if (action.payload.delivery_chilled !== undefined) {
        state.tenpoStatus.isChilled = makeBooleanValue(
          Number(action.payload.delivery_chilled || 0)
        );
      }
      if (action.payload.delivery_frozen !== undefined) {
        state.tenpoStatus.isFrozen = makeBooleanValue(
          Number(action.payload.delivery_frozen || 0)
        );
      }
    },
  },
});

export const selectTenpoStatus = (state: RootState) => state.tenpoStatus;
export const selectTenpoCode = (state: RootState) =>
  state.tenpoStatus.tenpoStatus.tenpoCode;
export default tenpoStatusSlice.reducer;
export const {
  logout,
  updateTenpoCode,
  updateIsKunren,
  updateIsEigyo,
  updateTenpoEigyoStatus,
  updateTenpoAnpiStatus,
  updateTenpoShopGoodsStatus,
  updateTenpoDelivery,
  updateTenpoStatus,
} = tenpoStatusSlice.actions;
