import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Route, BrowserRouter } from "react-router-dom";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import Auth from "./components/Auth/Auth";
import Home from "./components/Home/Home";
import Customer360Viewer from "./components/Customer360Viewer/Customer360Viewer";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <>
          <Route exact path="/login" component={Auth} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/customer-360-viewer" component={Customer360Viewer} />
          <Route path="/" component={App} />
        </>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
