import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
const microsoft365TenantId = process.env.REACT_APP_MICROSOFT365_TENANT_ID;
const provider = new firebase.auth.OAuthProvider("microsoft.com");

const firebaseApp = firebase.initializeApp(firebaseConfig);
// テナントを限定する
provider.setCustomParameters({ tenant: microsoft365TenantId });

export const db = firebaseApp.firestore();
export const firestore = firebase.firestore;
export const auth = firebase.auth();
export const storage = firebase.storage();
export const analytics = firebase.analytics();
// export const provider = new firebase.auth.GoogleAuthProvider();

export const microsoft365Login = () => {
  firebase.auth().signInWithRedirect(provider);
};
// Firebase Authentication API Token取得
export const getFirebaseAuthToken = async (isRefresh: boolean = true) => {
  return await auth.currentUser?.getIdToken(/* forceRefresh */ isRefresh);
};

// Firebaseユーザー情報取得
export const getFirebaseUserInfo = (): any => {
  return auth.currentUser !== null ? auth.currentUser : {};
};

// Firebaseユーザーメールアドレス取得
export const getFirebaseUserEmail = (): string => {
  // 開発環境:uid 試験環境・本番環境:email メールが含まれる方を返す
  const getUserEmail = (email: string, uid: string): string => {
    const mailCondition = (mode: string): string => {
      if (mode === "local" || mode.indexOf("dev") !== -1) {
        return "@";
      } else if (mode.indexOf("test") !== -1) {
        return "@";
      } else {
        return "@";
      }
    };
    if (email.indexOf(mailCondition(process.env.REACT_APP_MODE!)) !== -1) {
      return email;
    } else {
      return uid;
    }
  };
  let email = "", uid = "";
  if (auth.currentUser && auth.currentUser.email) {
    email = auth.currentUser.email;
  }
  if (auth.currentUser && auth.currentUser.uid) {
    uid = auth.currentUser.uid;
  }  
  return getUserEmail(email, uid);
};
